<template>
  <div>
    <ValidationObserver
      ref="updateMerchantBusinessBankDetailsForm"
      v-slot="{ handleSubmit }"
      slim
    >
      <b-form
        class="my-8 d-flex flex-wrap"
        @submit.prevent="handleSubmit(onClickUpdateMerchantBusinessBankDetails)"
      >
        <b-col cols="6">
          <b-form-group
            label="Account Name"
            label-for="h-accountName"
          >
            <ValidationProvider
              #default="{ errors }"
              name="accountName"
              rules="required"
            >
              <b-form-input
                id="accountName"
                v-model="form.accountName"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter the account name"
                type="text"
                autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Account No."
            label-for="h-accountNo"
          >
            <ValidationProvider
              #default="{ errors }"
              name="accountNo"
              rules="required"
            >
              <b-form-input
                id="accountNo"
                v-model="form.accountNo"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter the account no"
                type="text"
                autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Bank Name"
            label-for="h-bankName"
          >
            <ValidationProvider
              #default="{ errors }"
              name="bankName"
              rules="required"
            >
              <b-form-input
                id="bankName"
                v-model="form.bankName"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter the bank name"
                type="text"
                autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Branch Name"
            label-for="h-branchName"
          >
            <ValidationProvider
              #default="{ errors }"
              name="branchName"
              rules="required"
            >
              <b-form-input
                id="branchName"
                v-model="form.branchName"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter the branch name"
                type="text"
                autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Branch Code"
            label-for="h-branchCode"
          >
            <ValidationProvider
              #default="{ errors }"
              name="branchCode"
            >
              <b-form-input
                id="branchCode"
                v-model="form.branchCode"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter the branch code"
                type="text"
                autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->

        <b-col class="mt-5">
          <b-col class="d-flex justify-content-end mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mr-1 custom-button-color"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Clear
            </b-button>
          </b-col>
        </b-col>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    form: {},
    modalLoading: false,
    loading: false,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  mounted() {
    // Set the initial number of items
    this.fetchMerchantBusinessBankDetails()
  },
  methods: {
    onClickRefresh() {
      this.fetchMerchantBusinessBankDetails()
    },
    async fetchMerchantBusinessBankDetails() {
      try {
        const { data } = (await MerchantRepository.getMerchantBusinessDetails(this.$route.params.businessId)).data
        this.form = {
          accountName: data.business_bank_details.account_name,
          accountNo: data.business_bank_details.account_number,
          bankName: data.business_bank_details.bank_name,
          branchName: data.business_bank_details.branch_name,
          branchCode: data.business_bank_details.branch_code,
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async onClickUpdateMerchantBusinessBankDetails() {
      this.modalLoading = true
      try {
        const payload = {
          bank_details: {
            account_name: this.form.accountName,
            account_number: this.form.accountNo,
            bank_name: this.form.bankName,
            branch_name: this.form.branchName,
            branch_code: this.form.branchCode,
          },
        }

        await MerchantRepository.updateMerchantBusiness(this.$route.params.businessId, payload)
        this.showSuccessMessage('Merchant business bank details updated successfully')
        this.open = false
        this.$parent.onClickRefresh()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
@import '../../@core/scss/button-color.scss';
</style>
